import * as React from "react";
import { Badge, Button, Header, SpaceBetween } from "@cloudscape-design/components";
import { BadgeProps } from "@cloudscape-design/components/badge";
import { selectLocationData, selectCurrentProgramSetupTab } from "./appLayoutSlice";
import { useAppDispatch, useAppSelector } from "src/components/redux/hooks";
import { HOME_PATH_HEADER, DEFAULT_HEADER, TAB_ID, USER_ROLES, ProgramStatus } from "../../constants/constants";
import { updateOpenCreateFPNModal } from '../attributes/attributesSlice';
import { selectRoleResourceMap } from '../userPolicy/userPolicySlice';
import { selectVerityData } from "../programOverview/programOverviewSlice";


export const RPTPageHeader = () => {
    const dispatch = useAppDispatch();
    const { currentScreen, productLine, program, fpn } = useAppSelector(selectLocationData);
    const currentTab = useAppSelector(selectCurrentProgramSetupTab);
    const roleToResMap = useAppSelector(selectRoleResourceMap);
    const verityData = useAppSelector(selectVerityData);
    const hasCreateAccess = roleToResMap?.[USER_ROLES.plAdmin]?.length ? true : false;


    const badgeColorMap: Record<ProgramStatus, BadgeProps['color']> = {
        [ProgramStatus.ACTIVE]: 'green',
        [ProgramStatus.EOL]: 'blue',
        [ProgramStatus.CANCELLED]: 'red',
        [ProgramStatus.TOPSIDE]: 'grey'
    };

    const getBadgeColor = (status: string): BadgeProps['color'] | undefined => {
        return badgeColorMap[status as ProgramStatus];
    };

    /*
    * Each level contains data from its parent.
    * This means checking the leaf level first for what header to render.
    * The relationship is product line -> root program (RPN) -> finance program (FPN)
    */
    const getHeaderName = () => {
        if (fpn.id) return fpn.name;
        if (program.id) {
            const badgeColor = verityData.programStatus ? getBadgeColor(verityData.programStatus) : undefined;
            return (
                <SpaceBetween direction="horizontal" size="xs">
                    {program.name}
                    {badgeColor &&
                        <Badge color={badgeColor}>
                            {verityData.programStatus}
                        </Badge>
                    }
                </SpaceBetween>
            );
        }
        if (productLine.id) return productLine.name;
        if (currentScreen in HOME_PATH_HEADER) return HOME_PATH_HEADER[currentScreen as keyof typeof HOME_PATH_HEADER];
        return DEFAULT_HEADER;
    };

    return (
        <>
            <Header
                variant="h1"
                className="page-header "
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        {currentTab === TAB_ID.attributes && program.id && !fpn.id && hasCreateAccess && <Button ariaLabel="create-fpn" onClick={() => dispatch(updateOpenCreateFPNModal(true))}>Create FPNs</Button>}
                    </SpaceBetween>}
            >
                {getHeaderName()}
            </Header>
        </>

    );

};
