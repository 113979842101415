import React from "react";
import { ColDef, ColGroupDef, ValueGetterParams, ValueGetterFunc } from "ag-grid-community";
import PartNumBtnRenderer from "./partNumBtnRenderer";
import DeactivateReactivateBtnRenderer from "./deactivateBtnRenderer";
import { EditSkuAssortmentsBtnRenderer, EditSkuVersionBtnRenderer } from "./editSkuBtnRenderer";
import MarketPlaceRenderer from "./marketPlaceRenderer";
import {
    SkuFPNRenderer,
    SkuFPNIDRenderer
} from "src/components/AGM/features/sku/fpnRenderers";
import { concatWithoutDuplicate } from "./skuHelpers";
import {IPlFPNRuleResponse, IRpnFPNRuleResponse} from "src/components/RPT/interfaces/interfaces";
import { IIdName} from "src/components/AGM/interfaces/interfaces";
import { FPN_DOES_NOT_EXIST, NA} from "src/components/constants/constants";


export const fpnNameGenerator = (version: Record<string, any>, fpnRule: IRpnFPNRuleResponse[] | IPlFPNRuleResponse[]): string => {
    const program_name_attributes: string[] = []
    fpnRule.forEach((rule_item: IRpnFPNRuleResponse | IPlFPNRuleResponse)=> {
        if(
            ( rule_item.attribute_type.toUpperCase() in version)
            &&
            (version[rule_item.attribute_type.toUpperCase()] !== NA)
        )
        {
            program_name_attributes.push(version[rule_item.attribute_type.toUpperCase()]);
        }
    })
    return program_name_attributes.join('_');
}

export const fpnIDValueGetter = (fpns: IIdName[] | undefined): ValueGetterFunc => {
    return (params: ValueGetterParams) => {
        const expectedFPN: string = params.api.getValue("fpn", params.node!)
        const expectedFPNId: IIdName[] =  (fpns === undefined) ? [] as IIdName[]: fpns.filter((fpn)=>fpn.name.toUpperCase() === expectedFPN.toUpperCase());
        return expectedFPNId.length > 0 ? expectedFPNId[0].id : FPN_DOES_NOT_EXIST
    }
}

export const fpnValueGetter = (versions: Record<string, Record<string, any>>, programName: string, fpnRule: IRpnFPNRuleResponse[] | IPlFPNRuleResponse[]): ValueGetterFunc => {
return (params: ValueGetterParams)=> {
    const version: Record<string, any> = {
        ...versions[params.node?.data.version_id],
        "PROGRAM": programName,
    };
    return fpnNameGenerator(version, fpnRule);
}
}

export const editSKUAssortmentsModalColDefs = (versions: Record<string, Record<string, any>>, fpnRule: IRpnFPNRuleResponse[] | IPlFPNRuleResponse[], programName: string, fpns: IIdName[] | undefined): (ColDef | ColGroupDef)[] => {
    return [
        {
            headerName: "Product Version",
            field: "product_version",
            editable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            valueGetter: (params: ValueGetterParams) => {
                return versions[params.node?.data.version_id]?.description;
            },
            minWidth: 500
        },
        {
            headerName: "Verity FPN ID",
            field: "verity_fpn_id",
            editable: false,
            cellRenderer: SkuFPNIDRenderer,
            cellRendererParams: {fpns: fpns},
            valueGetter: fpnIDValueGetter(fpns),
        },
        {
            headerName: "FPN",
            field: "fpn",
            editable: false,
            cellRenderer: SkuFPNRenderer,
            cellRendererParams: {fpns},
            valueGetter: fpnValueGetter(versions, programName, fpnRule)
        },
        {
            headerName: "SKU Type",
            field: "sku_type",
            editable: false
        },
        {
            headerName: "Country",
            field: "country",
            editable: false,
            maxWidth:150
        },
        {
            headerName: "ROW",
            field: "row",
            editable: false,
            maxWidth:150
        },
        {
            headerName: "Channel",
            field: "channel",
            editable: false,
            maxWidth:150
        },
        {
            headerName: "Comment",
            field: "comment",
            editable: false
        }
    ];
};

export const editSKUVersionModalColDefs = (versions: Record<string, Record<string, any>>, fpnRule: IRpnFPNRuleResponse[] | IPlFPNRuleResponse[], programName: string, fpns: IIdName[] | undefined): (ColDef | ColGroupDef)[] => {
    return [
        {
            headerName: "Product Version",
            field: "product_version",
            editable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            valueGetter: (params: ValueGetterParams) => {
                return versions[params.node?.data.version_id]?.description;
            }
        },
        {
            headerName: "Verity FPN ID",
            field: "verity_fpn_id",
            editable: false,
            cellRenderer: SkuFPNIDRenderer,
            cellRendererParams: {fpns},
            valueGetter: fpnIDValueGetter(fpns),
        },
        {
            headerName: "FPN",
            field: "fpn",
            editable: false,
            cellRenderer: SkuFPNRenderer,
            cellRendererParams: {fpns},
            valueGetter: fpnValueGetter(versions, programName, fpnRule)
        },
        {
            headerName: "SKU Type",
            field: "sku_type",
            editable: false
        },
        {
            headerName: "Country",
            field: "country",
            editable: false,
            maxWidth: 130
        },
        {
            headerName: "ROW",
            field: "row",
            editable: false,
            maxWidth: 130
        },
        {
            headerName: "Channel",
            field: "channel",
            editable: false,
            maxWidth: 200

        },
        {
            headerName: "Comment",
            field: "comment",
            editable: false
        }
    ];
};

export const addSKUModalColDefs = (versions: Record<string, Record<string, any>>, fpnRule: IRpnFPNRuleResponse[] | IPlFPNRuleResponse[], programName: string, fpns: IIdName[] | undefined): (ColDef | ColGroupDef)[] => {
    return [
        {
            headerName: "Product Version",
            field: "product_version",
            editable: false,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            valueGetter: (params: ValueGetterParams) => {
                return versions[params.node?.data.version_id]?.description;
            }
        },
        {
            headerName: "Verity FPN ID",
            field: "verity_fpn_id",
            editable: false,
            cellRenderer: SkuFPNIDRenderer,
            cellRendererParams: {fpns},
            valueGetter: fpnIDValueGetter(fpns),
        },
        {
            headerName: "FPN",
            field: "fpn",
            editable: false,
            cellRenderer: SkuFPNRenderer,
            cellRendererParams: {fpns},
            valueGetter: fpnValueGetter(versions, programName, fpnRule)
        },
        {
            headerName: "SKU Type",
            field: "sku_type",
            editable: false
        },
        {
            headerName: "Country",
            field: "country",
            editable: false,
            maxWidth: 130
        },
        {
            headerName: "ROW",
            field: "row",
            editable: false,
            maxWidth: 130
        },
        {
            headerName: "Channel",
            field: "channel",
            editable: false,
            maxWidth: 200

        },
        {
            headerName: "Comment",
            field: "comment",
            editable: false
        }
    ];
};

export const skuColDefs = (
    hasWriteAccess: boolean,
    hasDeactivateAccess: boolean,
    requestPNHandler: Function,
    addNotificationItem: Function,
    versions: Record<string, Record<string, any>>,
    fpnRule: IRpnFPNRuleResponse[] | IPlFPNRuleResponse[],
    fpns: IIdName[] | undefined,
    programName: string,
    toggleMPEditModal: Function,
    toggleSKUAssortmentsEditModal: Function,
    toggleSKUVersionEditModal: Function,
    deactivateSKUHandler: Function,
    reactivateSKUHandler: Function,
): (ColDef | ColGroupDef)[]  => {
    return [
        {
            headerName: "SKU id",
            field: "sku_id",
            editable: false,
            hide: true
        },
        {
            headerName: "Part Number",
            field: "pn",
            editable: false,
            cellRenderer: PartNumBtnRenderer,
            cellRendererParams: {
                requestPNHandler,
                addNotificationItem,
                hasWriteAccess
            }
        },
        {
            headerName: "ASIN",
            field: "asin",
            editable: false
        },
        {
            headerName: "Description",
            field: "description",
            editable: false,
            valueGetter: (params: ValueGetterParams) => {
                params.node!.data.description = versions[params.node?.data.version_id]?.description;
                params.node!.data.descriptionPN = versions[params.node?.data.version_id]?.descriptionPN;
                return params.node!.data.description;
            }
        },
        {
            headerName: "Verity FPN ID",
            field: "verity_fpn_id",
            editable: false,
            cellRenderer: SkuFPNIDRenderer,
            cellRendererParams: {fpns},
            valueGetter: fpnIDValueGetter(fpns),
        },
        {
            headerName: "FPN",
            field: "fpn",
            editable: false,
            cellRenderer: SkuFPNRenderer,
            cellRendererParams: {fpns},
            valueGetter: fpnValueGetter(versions, programName, fpnRule)
        },
        {
            headerName: "SKU Type",
            field: "sku_type",
            editable: false
        },
        {
            headerName: "Country",
            field: "country",
            editable: false
        },
        {
            headerName: "MarketPlace",
            field: "marketPlace",
            editable: false,
            valueGetter: (params: ValueGetterParams)=>{
                const data = params.node?.data;
                return concatWithoutDuplicate(data?.marketPlace, data?.additional_marketplaces).join(",");
            },
            cellRenderer: MarketPlaceRenderer,
            cellRendererParams:{
                toggleMPEditModal,
                hasWriteAccess
            }
        },
        {
            headerName: "Edit Assortments",
            editable: false,
            cellRenderer: EditSkuAssortmentsBtnRenderer,
            cellRendererParams: {
                toggleSKUAssortmentsEditModal,
                hasWriteAccess
            },
            maxWidth: 160
        },
        {
            headerName: "Edit Version",
            editable: false,
            cellRenderer: EditSkuVersionBtnRenderer,
            cellRendererParams: {
                toggleSKUVersionEditModal,
                hasWriteAccess,
                hasDeactivateAccess
            },
            maxWidth: 130
        },
        {
            headerName: "De/Reactivate",
            field: "is_deactivated",
            editable: false,
            valueGetter: (params: ValueGetterParams)=> params.node?.data.is_deactivated ? true : false,
            cellRenderer: DeactivateReactivateBtnRenderer,
            cellRendererParams: {
                deactivateSKUHandler,
                reactivateSKUHandler,
                addNotificationItem,
                hasWriteAccess,
                hasDeactivateAccess,
            },
            minWidth: 200
        }
    ];
};
