import { ColDef, ColGroupDef, GridOptions, ValueGetterParams } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import { GridTable } from 'src/components/common/GridTable/GridTable';
import { useAppDispatch, useAppSelector } from 'src/components/redux/hooks';
import { getAssortments } from '../assortment/assortmentSlice';
import { selectVersions } from '../versions/versionSlice';
import { Box, Button, FlashbarProps, Modal, SpaceBetween, Toggle, Header, Link } from '@amzn/awsui-components-react';
import { useAddSkuMutation } from '../../services/apis/api';
import { STATUS_CODES } from '../../constants/constants';
import { getAssignedAssortments, getSelectedAssortments, updateSelectedAssortments } from './skuSlice';
import { assortment } from '../../interfaces/assortmentInterface';
import { skuPayloadItem } from '../../interfaces/skuInterface';
import { addSKUModalColDefs } from './columnDefs';
import {selectLocationData, selectRpnToFpnMap} from "src/components/AGM/features/appLayout/appLayoutSlice";
import {selectPlFPNRule, selectRPNFPNRule} from "src/components/RPT/features/attributes/attributesSlice";
import {IPlFPNRuleResponse, IRpnFPNRuleResponse} from "src/components/RPT/interfaces/interfaces";
import {IIdName} from "src/components/AGM/interfaces/interfaces";


export default function AddNewSkuModal({ visible, setVisible, addNotificationItem }: {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    addNotificationItem: (notificationItem: FlashbarProps.MessageDefinition) => void
}) {

    //hooks
    const dispatch = useAppDispatch();
    const [ addSku ] = useAddSkuMutation();


    //from store
    const locationData = useAppSelector(selectLocationData);
    const assignedAssortments = useAppSelector(getAssignedAssortments);
    const { assortments } = useAppSelector(getAssortments);
    const versions = useAppSelector(selectVersions);
    const selectedAssortments = useAppSelector(getSelectedAssortments);
    const plFPNRule = useAppSelector(selectPlFPNRule);
    const rpnFPNRule = useAppSelector(selectRPNFPNRule);
    const fpnRule: IRpnFPNRuleResponse[] | IPlFPNRuleResponse[]  = rpnFPNRule ?? plFPNRule ?? [] as IPlFPNRuleResponse[]
    const rpnFPNMap: Record<string, IIdName[]> = useAppSelector(selectRpnToFpnMap)

    //state
    const [savingSku, setSavingSku] = useState(false);
    const [hideAssigned, setHideAssigned] = useState(true);


    //locals
    const { program } = locationData;
    const fpns: IIdName[] = rpnFPNMap[program.id];

    const onRowSelection = (selectedRows: assortment[]) => {
        const selectedItems = selectedRows.reduce((map, item) => {
            const key = `${item.version_id}_${item.sku_type}`;
            if (map[key]) {
                map[key].assortment_ids = [...map[key].assortment_ids, item.assortment_id];
            } else {
                map = {
                    ...map,
                    [key]: {
                        version_id: item.version_id,
                        sku_type: item.sku_type,
                        assortment_ids: [item.assortment_id]
                    }
                };
            }
            return map;
        }, {} as Record<string, skuPayloadItem>);

        dispatch(updateSelectedAssortments(selectedItems));
    };

    const addSkuHandler = async () => {
        try {
            const assortmentList =  Object.values(selectedAssortments);
            if(assortmentList.length){
                setSavingSku(true);
                const payload = {
                    input: assortmentList
                };
                const response =  await addSku(payload).unwrap();

                if (response.statusCode === STATUS_CODES.success) {
                    addNotificationItem({
                        type: "success",
                        dismissible: true,
                        content: "SKU added successfully",
                        id: "SKU_SAVE_SUCCESS"
                    });
                } else {
                    throw new Error(response.error);
                }
            }
        } catch (error) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: "Failed to add SKU.",
                id: "SKU_SAVE_FAILED"
            });
        }finally {
            setSavingSku(false);
            setVisible(false);
        }
    };

    const customSettings = {
        className: 'ag-theme-alpine',
        readOnly: false,
        allowLocalFilters: true,
        filterId: 'assortments',
        onlyGrid: true,
        onRowSelection
    };

    const columnDefs: (ColDef | ColGroupDef)[] = addSKUModalColDefs(versions, fpnRule, program.name, fpns);

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: columnDefs,
        rowData: [],
        rowSelection: 'multiple',
        suppressRowClickSelection: true
    });


    useEffect(() => {
        if (versions && assortments && assignedAssortments) {
            const map = Object.values(assortments).map(assortment => {
                const key = `${assortment.version_id}_${assortment.sku_type}_${assortment.channel}_${assortment.country ?? assortment.row}`;
                return {
                    ...assortment,
                    comment: assignedAssortments.includes(key) ? "Assortment already assigned to Existing SKU." : ''
                };
            });
            setGridOptions((prevState) => {
                return {
                    ...prevState,
                    rowData: hideAssigned ? map.filter((item) => !item.comment) : [...map]
                };
            });
        }
    }, [versions, assortments, assignedAssortments, hideAssigned]);



    return (
        <>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                size="max"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
                            <Button variant="primary" onClick={addSkuHandler} loading={savingSku} disabled={!Object.values(selectedAssortments).length}>Add SKU</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Add SKU"
            >
                <SpaceBetween size='l'>
                    <div className='toggle'>
                        <Toggle
                            onChange={({ detail }) =>
                                setHideAssigned(detail.checked)
                            }
                            checked={hideAssigned}
                        >
                        Hide assigned assortments
                        </Toggle>
                    </div>
                    <GridTable
                        gridSettings={gridOptions}
                        customSettings={{
                            ...customSettings
                        }}
                    />
                </SpaceBetween>
            </Modal>
        </>
    );
}
