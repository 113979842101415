export const LandingPageResourceSection = {
  "Header": "More resources ",
  "documentationHeader": "Documentation Wiki",
  "documentationLink": "https://w.amazon.com/bin/view/DaS-FinTech/Products/Verity2/",
  "contactHeader": "Contact tool support",
  "contactLink": "https://t.corp.amazon.com/create/templates/ce4bc0e2-64b9-4b5b-af44-0cabc730fce7",
  "requestReportAccessHeader": "Request report access",
  "requestReportAccessLink": "https://permissions.amazon.com/a/team/Lab126%20Finance%20BI%20Shared%20Reports",
  "fintechPortalHeader": "Device Fintech Portal",
  "fintechPortalLink": "https://fintech.device.finance.amazon.dev/",
  "enhancementRequestHeader": "Request enhancement",
  "enhancementRequestLink": "https://form.asana.com/?k=JSROdvXhiLc0vIc9cG9-xw&d=8442528107068",
  "newProjectRequestHeader": "Request new project",
  "newProjectRequestLink": "https://form.asana.com/?k=gwH_umHkw7x2dEe7Ep17eQ&d=8442528107068",
  "updateProgramInPPLSHeader": "Update program in PPLS",
  "updateProgramInPPLSLink": "https://sim.amazon.com/issues/create?template=7aeb673c-8deb-4131-9c95-ed573da17067",
}

export const LandingPageSection = {
  "MainHeader": "Verity",
  "MainHeaderTagline": "DaS FinTech's metadata governance tool",
  "HowItWorks": "How it works",
  "HowItWorksDescription": "Verity is DaS FinTech's metadata governance tool, providing the full hierarchy from Product Line to ASIN. Verity aims to be the single source of truth for all DaS metadata from program to ASIN level, in order to provide downstream systems and users with consistent data hierarchies.",
}

export const LandingPageReportSection = {
  "Header": "Where's my data?",
  "Description": "Use these reports to trace where your data is.",
}