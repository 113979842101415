import React from "react";
import {Box, Grid, Container, Link} from "@cloudscape-design/components";
import { useAppSelector } from "../../../redux/hooks";
import { selectPlFPNRule } from './attributesSlice';
import { PopoverInfo } from "../../../common/Components/Popover/popover";
import { selectVerityData } from "../programOverview/programOverviewSlice";
import {useHelpPanel} from "src/components/common/HelpPanel/useHelpPanel";
import {GeneralInfoHelpPanel} from "src/components/RPT/features/attributes/generalInfoHelpPanel";

export const GeneralDetails = () => {
    const { setIsOpen, isOpen} = useHelpPanel();
    const plFpnRule = useAppSelector(selectPlFPNRule);
    const { asinCount } = useAppSelector(selectVerityData);
    const generateFpnRuleString = () => plFpnRule?.map(item => item.is_required ? item.attribute_type : `[${item.attribute_type}]`).join('_');
    return (
        <>

            <Container>
                <Grid
                    gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
                    <Box >
                        <Box variant="awsui-key-label">Number of ASINs</Box>
                        <Box >{asinCount !== -1 ? asinCount : ''}</Box>
                    </Box>
                    <Box className="border-left" padding={{ left: "xl" }}>
                        <Box variant="awsui-key-label">FPN rule <Link variant='info' onFollow={() => setIsOpen(!isOpen)}> Info </Link></Box>
                        <Box > {generateFpnRuleString()}</Box>
                    </Box>

                </Grid>
            </Container>
        </>
    );
};
