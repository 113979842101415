import React from 'react';
import {
    Header,
    HelpPanel,
    Icon,
    Link,
} from '@cloudscape-design/components';

import {
    LandingPageResourceSection
} from 'src/components/AppComponents/landingPageConstants';

export const AssortmentHelpPanel: React.FC = () => {
    return (
        <HelpPanel

            header={<Header>Assortments</Header>}
            footer={
                <div>
                    <h3>Further questions{" "}<Icon name="external" size="inherit"/></h3>
                    <ul>
                        <div><Link href={LandingPageResourceSection.contactLink} target="_blank">Create SIM
                            ticket</Link></div>
                    </ul>
                </div>
            }
        >
            <h3>Assortments</h3>
            <p>The assortments define the possible countries, channels, and SKU type combinations for this program and version.</p>
        </HelpPanel>
    );
};
