import React from 'react';
import {StatusIndicator} from "@cloudscape-design/components";
import {IIdName} from "src/components/AGM/interfaces/interfaces";


const skuGetStatusType = (fpnNames: string[], expectedFPN: string) => {
    if (fpnNames.includes(expectedFPN.toUpperCase())){
        return 'success'
    } else {
        return 'warning'
    }
}


export const SkuFPNIDRenderer = (props: any)=>{

    const fpnNames: string[] = (props.fpns === undefined) ? [] as string[] : props.fpns.map((fpn: IIdName) => fpn.name.toUpperCase());
    return (
        <StatusIndicator type={skuGetStatusType(fpnNames, props.api.getValue("fpn", props.node))}> {props.value} </StatusIndicator>
    )

}


export const SkuFPNRenderer = (props: any) => {
    const fpnNames: string[] = (props.fpns === undefined) ? [] as string[] : props.fpns.map((fpn: IIdName) => fpn.name.toUpperCase());
    return (
        <StatusIndicator type={skuGetStatusType(fpnNames, props.value)}> {props.value} </StatusIndicator>
    )
}