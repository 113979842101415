import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Header, SpaceBetween } from "@cloudscape-design/components";
import { VersionsTable } from './versionsTable';
import { useAppSelector } from '../../../redux/hooks';
import { AddAttributeModal } from './addAttributesModal';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { ALL_ASSIGNEES_KEY, USER_ACTION_ROLES } from '../../constants/constants';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { selectAgmRoleResourceMap } from '../userPolicy/userPolicySlice';
import { selectVerityData } from 'src/components/RPT/features/programOverview/programOverviewSlice';
import {useHelpPanel} from "src/components/common/HelpPanel/useHelpPanel";
import {Link} from "@amzn/awsui-components-react";
import {VersionHelpPanel} from "src/components/AGM/features/versions/versionsHelpPanel";

export const Versions = () => {
    const { setHelpPanelContent, setIsOpen, isOpen } = useHelpPanel();
    const { getUserRole } = UserPolicyHelper();
    const [modalVisible, setModalVisible] = useState(false);
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const roleResMap = useAppSelector(selectAgmRoleResourceMap);
    const pplsId = useAppSelector(selectVerityData).ppls_id;
    const { productLine, program } = useAppSelector(selectLocationData);

    useEffect(()=> {
        setHelpPanelContent(<VersionHelpPanel />)
    }, []);

    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY]);
        setHasWriteAccess((USER_ACTION_ROLES.write.includes(currRole) && pplsId?.length > 0) ? true : false);
    }, [roleResMap, program, productLine, pplsId]);

    return (
        <>
            <Container
                header={
                    <Header
                        variant="h2"
                        description=""
                        actions={
                            <SpaceBetween
                                direction="horizontal"
                                size="xs"
                            >
                                <Button disabled={!hasWriteAccess} onClick={() => setModalVisible(true)}>Modify attributes</Button>
                            </SpaceBetween>
                        }
                        info={<Link variant="info" onFollow={() => setIsOpen(!isOpen)}>Info</Link>}
                    >
                        Versions
                    </Header>
                }
            >
                <Box padding={{ top: "xl" }}> <VersionsTable hasWriteAccess={hasWriteAccess} /></Box>
            </Container>
            {modalVisible && <AddAttributeModal hasWriteAccess={hasWriteAccess} setVisible={setModalVisible} />}
        </>

    );
};
