import { assortment } from '../../interfaces/assortmentInterface';
import { skuItem, groupedSKUItem } from '../../interfaces/skuInterface';

export const getUniqueString = (existingString: string[], newString: string)=>{
    if(!newString || existingString.indexOf(newString) > -1){
        return existingString;
    }
    return [...existingString, newString];
};

export const groupSKUs = (skus: skuItem[], assortments: Record<string, assortment>) => {
    return skus.reduce((map ,skuItem: skuItem) => {
        const key = `${skuItem.sku_id}_${skuItem.version_id}_${skuItem.sku_type}`;
        if(map[key]){
            map = {
                ...map,
                [key]: {
                    ...map[key],
                    country: getUniqueString(map[key]?.country, assortments[skuItem.assortment_id]?.country ?? assortments[skuItem.assortment_id]?.row),
                    marketPlace: getUniqueString(map[key]?.marketPlace, assortments[skuItem.assortment_id]?.marketplace),
                    groupedAssortmentIDs: [...map[key]?.groupedAssortmentIDs, skuItem.assortment_id],
                }
            };
        }else {
            map = {
                ...map,
                [key]: {
                    ...skuItem,
                    country: [assortments[skuItem.assortment_id]?.country ?? assortments[skuItem.assortment_id]?.row] ?? [],
                    marketPlace:  assortments[skuItem.assortment_id]?.marketplace ? [assortments[skuItem.assortment_id]?.marketplace] : [],
                    groupedAssortmentIDs: [skuItem.assortment_id],
                    key
                }
            };
        }
        return map ;
    }, {} as Record<string, groupedSKUItem>);
};

export const concatWithoutDuplicate = (arr1: string[] = [] , arr2: string[] = []) => {
    return  [...new Set(arr1.concat(arr2))];
};

export const compareArrays = (arr1: number[], arr2: number[]) => {
    const added = arr2.filter(item => !arr1.includes(item));
    const removed = arr1.filter(item => !arr2.includes(item));

    return { added, removed };
};
