import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { FormField, RadioGroup, SpaceBetween, Modal, Box, Button, Alert } from '@amzn/awsui-components-react';
import { useAppSelector } from 'src/components/redux/hooks';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { useCreateProgramPPLSMutation } from '../../services/api';
import AlertMessageContext from 'src/components/common/Contexts/alertMessageContext';
import { ALERT_MESSAGES, MUTATION_METHODS, SELECT_OPTIONS, STATUS_CODES, PPLS_PROGRAM_STATUS } from '../../constants/constants';
import { PROGRAM_ALERT_MESSAGES } from '../../constants/displayMessages';
import { selectVerityData } from './programOverviewSlice';
import { IApiUpdateResponse } from '../../../Interfaces/interface';
import ScreenUtils from '../../utils/screenUtils';

export interface ICreateProgramPPLSProps {
    visible: boolean;
    setVisible: (arg: boolean) => void;
}

export interface ICreateProgramPPLSData {
    confidential: string
}

export const CreateProgramPPLS = ({
    visible,
    setVisible
}: ICreateProgramPPLSProps) => {
    const { productLine, program } = useAppSelector(selectLocationData);
    const [createProgramPPLS, { isLoading, data: mutationData, isError: mutationError }] = useCreateProgramPPLSMutation();
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const [formInput, setFormInput] = useState<ICreateProgramPPLSData>({
        confidential: 'No'
    });
    const programData = useAppSelector(selectVerityData);

    const setFieldValue = (field: string, value: any) => {
        setFormInput(values => ({
            ...values,
            [field]: value
        }));
    };

    const create = async () => {
        const payload = {
            id: parseInt(program.id),
            name: programData.programName,
            verity_product_line_id: parseInt(productLine.id),
            confidential: formInput.confidential === 'No' ? false : true,
            status: PPLS_PROGRAM_STATUS.active
        };
        await createProgramPPLS(ScreenUtils.getGraphQLPayload(payload)).unwrap();
    };

    useEffect(() => {
        if (mutationData) {
            if (mutationData.errors || mutationError) {
                setError?.(PROGRAM_ALERT_MESSAGES.pplsCreateError);
                setVisible(false);
            } else if (mutationData.data?.[MUTATION_METHODS.pplsCreate]) {
                const { statusCode, error }: IApiUpdateResponse = mutationData.data[MUTATION_METHODS.pplsCreate];
                statusCode === STATUS_CODES.success ? setSuccess?.(PROGRAM_ALERT_MESSAGES.pplsCreateSuccess) :
                    (statusCode === STATUS_CODES.handledException && error) ? setError?.(error) : setError?.(ALERT_MESSAGES.programFailure);
                setVisible(false);
            }
        }
    }, [mutationData, mutationError]);

    return (
        <>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header='Create program in PPLS'
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setVisible(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={create} loading={isLoading}>Create</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <SpaceBetween size='l' >
                    <Box>
                        <Box variant="awsui-key-label">Program name</Box>
                        <Box variant="span">{programData.programName}</Box>
                    </Box>
                    <FormField label="Confidential">
                        <RadioGroup

                            className="rpt-radio-group"
                            onChange={({ detail }) => {
                                if (!isLoading) setFieldValue("confidential", detail.value)
                            }}
                            value={formInput["confidential"]!}
                            items={SELECT_OPTIONS.yesNo}
                        />
                        {formInput["confidential"] === "Yes" && (
                            <Alert
                                type="warning"
                                statusIconAriaLabel="Warning"
                            >
                                'Warning: All Devices Programs and Product Lines are already restricted to Device employees. 
                                Only NPI Programs (non-Gen over Gen) and special Product Lines should consider additional confidentially. 
                                Does this program require additional confidentiality? 
                                <a href="https://sim.amazon.com/issues/create?template=7aeb673c-8deb-4131-9c95-ed573da17067" target="_blank"> Ticket</a> to PPLS team will be required to change it to non-confidentiality.'
                            </Alert>
                        )}
                    </FormField>
                </SpaceBetween>
            </Modal>
        </>
    );
};
