export const MUTATION_METHODS = {
    updateAttrTypeMaster: 'post_attr_type_master',
    updateAttrListMaster: 'post_attr_list_master',
    updateAttrTypeRPN: 'post_attr_type_rpn',
    updateAttrListRPN: 'post_attr_list_rpn',
    updateRpnFpnRule: 'post_rpn_fpn_rule',
    updateAttrListFPN: 'post_attr_list_fpn'
};

export const API_METHODS = {
    get: 'GET',
    post: 'POST'
};

export const FPN_DOES_NOT_EXIST: string = "FPN does not exist"
export const NA: string = "N/A"


export enum PrfaqApprovalStatus {
    Yes= "Yes",
    No= "No",
    NA= "N/A"
}