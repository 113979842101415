import React, { useEffect } from 'react';
import { Tabs, TabsProps } from "@cloudscape-design/components";
import { TAB_ID_LABEL, TAB_ID } from '../../constants/constants';
import { Versions } from '../versions/versions';
import Assortment from '../assortment/assortment';
import { useAppSelector } from 'src/components/redux/hooks';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { Attributes } from 'src/components/RPT/features/attributes/attributesHome';
import Sku from 'src/components/AGM/features/sku/sku';
import {useHelpPanel} from "src/components/common/HelpPanel/useHelpPanel";
import {GeneralInfoHelpPanel} from "src/components/RPT/features/attributes/generalInfoHelpPanel";
import {SkuHelpPanel} from "src/components/AGM/features/sku/skuHelpPanel";
import {VersionHelpPanel} from "src/components/AGM/features/versions/versionsHelpPanel";
import {AssortmentHelpPanel} from "src/components/AGM/features/assortment/assortmentsHelpPanel";

export const ProgramHomeTabs = () => {
    const { setHelpPanelContent } = useHelpPanel();

    const locationData = useAppSelector(selectLocationData);

    //locals
    const { program } = locationData;

    const [activeTabId, setActiveTabId] = React.useState(
        TAB_ID.attributes
    );

    useEffect(() => {
        setActiveTabId( TAB_ID.attributes);
    }, [program]);

    const loadSetupTabs = () => {
        const tabs: TabsProps.Tab[] = [
            {
                label: TAB_ID_LABEL.attributes,
                id: TAB_ID.attributes,
                content: <Attributes/>
            },
            {
                label: TAB_ID_LABEL.versions,
                id: TAB_ID.versions,
                content: <Versions />
            },
            {
                label: TAB_ID_LABEL.assortments,
                id: TAB_ID.assortments,
                content: <Assortment />
            },
            {
                label: TAB_ID_LABEL.sku,
                id: TAB_ID.sku,
                content: <Sku />
            }
        ];
        return (
            <Tabs
                onChange={({ detail }) => {
                    setActiveTabId(detail.activeTabId);
                }
                }
                activeTabId={activeTabId}
                tabs={tabs}
                ariaLabel="Program"
            />
        );
    };
    return (
        <>
            {loadSetupTabs()}
        </>

    );
};

