import React, { useEffect, useState } from 'react';
import { AppLayout, Box, ContentLayout } from "@cloudscape-design/components";
import { Flashbar } from '@amzn/awsui-components-react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { useAppDispatch } from '../../../redux/hooks';
import { AGMSideNav } from './sideNav';
import { AlertMessageProvider } from '../../../common/Contexts/alertMessageContext';
import { AGMBreadcrumbs } from './breadcrumbs';
import { AGMHeader } from './header';
import { useGetProductHierarchyQuery } from '../../../redux/services/commonApi';
import { useGetAttributeListMasterQuery, useGetAttributeTypeMasterQuery } from '../../../redux/services/attributesApi';
import { useGetAGMUserPolicyDataQuery } from '../../services/apis/userPolicyApi';
import { updateLocationData } from './appLayoutSlice';
import { updateLocationData as updateLocationDataPRM} from '../../../RPT/features/appLayout/appLayoutSlice';
import { updateMasterAttrTypeListMap} from '../../../RPT/features/attributes/attributesSlice';
import AppLayoutHelper from './appLayoutHelper';
import { IApiResponse } from 'src/components/Interfaces/interface';
import { STATUS_CODES, SUPPORT_WIKI_LINK } from '../../constants/constants';
import { IAttributeListResponse, IAttributeTypeResponse } from '../../../Interfaces/attributesInterfaces';
import { TopNav } from 'src/components/common/Header/topNav';
import { appName } from 'src/constants/constants';
import ScreenUtils from '../../utils/screenUtils';
import useNotification from 'src/components/Hooks/notifications';
import { APP_LAYOUT_MESSAGES } from '../../constants/userMessages';
import {useHelpPanel} from "src/components/common/HelpPanel/useHelpPanel";
import {GeneralInfoHelpPanel} from "src/components/RPT/features/attributes/generalInfoHelpPanel";

export function AGMAppLayout() {
    const dispatch = useAppDispatch();
    const { setHelpPanelContent, setIsOpen, isOpen, helpPanelContent } = useHelpPanel();
    const { getUserId } = ScreenUtils;
    const username = getUserId();
    const { data: userPolicyData, isError: userPolicyError, isLoading: policyLoading, isFetching: policyFetching } = useGetAGMUserPolicyDataQuery({ userName: username });
    const { data: prodHierarchy, isError: prodHierarchyError } = useGetProductHierarchyQuery();
    const { generateLoactionData } = AppLayoutHelper();
    const [hideSideNav] = useState<boolean>(false);
    const [navigationOpen, setNavigationOpen] = useState<boolean>(true);
    const location = useLocation();
    const [sideNavError, setSideNavError] = useState(false);
    const [policyError, setPolicyError] = useState(false);
    const [queryParameters] = useSearchParams();
    const { data: attrListData } = useGetAttributeListMasterQuery();
    const { data: attrTypeData } = useGetAttributeTypeMasterQuery();
    const { notificationItems, addNotificationItem, clearNotifications } = useNotification();

    // Default to General Info Help Panel on initial render
    useEffect(()=> {
        setHelpPanelContent(<GeneralInfoHelpPanel />)
    }, [])

    useEffect(() => {
        const locData = generateLoactionData(queryParameters, location.pathname);
        dispatch(updateLocationData(locData));
        dispatch(updateLocationDataPRM(locData)); //Temporary : Remove after code refactor
    }, [location.pathname, queryParameters]);

    // This fix needs to be added on main home page but currently facing issues so adding for PRM & AGM seperately
    useEffect(() => {
        if (!username.length) window.location.reload();
    }, [username]);

    useEffect(() => {
        if (attrTypeData && attrListData) {
            const typeToListMap: Record<string, string[]> = {};
            attrTypeData.forEach((obj: IAttributeTypeResponse) => typeToListMap[obj.attribute_type] = []);
            attrListData.forEach((obj: IAttributeListResponse) => {
                const attrType = obj.attribute_type;
                if (typeToListMap[attrType]) typeToListMap[attrType].push(obj.attribute_value);
            });
            Object.entries(typeToListMap).forEach(([key, values]) => typeToListMap[key] = values.sort());
            dispatch(updateMasterAttrTypeListMap(typeToListMap));
        }
    }, [attrTypeData, attrListData]);

    useEffect(() => {
        if (prodHierarchy) {
            if (prodHierarchy.data?.get_nav_bar) {
                const { statusCode }: IApiResponse = prodHierarchy.data?.get_nav_bar;
                statusCode === STATUS_CODES.success ? setSideNavError(false) : setSideNavError(true);
            }
            if (prodHierarchy.errors || prodHierarchyError) setSideNavError(true);
        }
    }, [prodHierarchy, prodHierarchyError]);

    useEffect(() => {
        if (userPolicyData) {
            if (userPolicyData.data?.get_user_access) {
                const { statusCode }: IApiResponse = userPolicyData.data?.get_user_access;
                statusCode === STATUS_CODES.success ? setPolicyError(false) : setPolicyError(true);
            }
            if (userPolicyData.errors || userPolicyError) setPolicyError(true);
        }
    }, [userPolicyData, userPolicyError]);

    useEffect(() => {
        clearNotifications();
        if (policyError) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: APP_LAYOUT_MESSAGES.userPolicyError,
                id: "POLICY_ERROR"
            });
        }
        if (sideNavError) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: APP_LAYOUT_MESSAGES.progLoadingError,
                id: "SIDE_NAV_ERROR"
            });
        }
    }, [policyError, sideNavError]);

    return (
        <>
            <AlertMessageProvider>
                <TopNav
                    appName={appName}
                    NavUtilities={[
                        {
                            type: "button",
                            text: "Support",
                            href: SUPPORT_WIKI_LINK,
                            external: true
                        }
                    ]}
                />
                <AppLayout
                    id="rptAppLayout"
                    breadcrumbs={<AGMBreadcrumbs />}
                    content={
                        <ContentLayout
                            disableOverlap
                            header={<AGMHeader />}
                        >
                            <Box margin={{ top: "l" }}>
                                <Outlet />
                            </Box>
                        </ContentLayout>
                    }
                    navigation={<AGMSideNav hasError={sideNavError} policyLoading={policyFetching || policyLoading} />}
                    notifications={<Flashbar items={notificationItems} stackItems/>}
                    navigationHide={hideSideNav}
                    navigationOpen={navigationOpen}
                    onNavigationChange={() => setNavigationOpen(!navigationOpen)}
                    tools={helpPanelContent}
                    toolsOpen={isOpen}
                    onToolsChange={() => setIsOpen(!isOpen)}
                    maxContentWidth={Number.MAX_SAFE_INTEGER}
                    contentType="default"
                />
            </AlertMessageProvider>
        </>
    );
}