import React, { useEffect, useState } from 'react';
import { Box, SpaceBetween, Spinner } from "@cloudscape-design/components";
import { ContactInformation } from './contactInformation';
import { StatusInformation } from './statusInformation';
import { ProgramOverview } from './programOverview';
import { Documents } from './documents';
import { SubmitOverview } from './submitOverview';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectRoleResourceMap } from '../userPolicy/userPolicySlice';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { ALL_ASSIGNEES_KEY, USER_ACTION_ROLES, ProgramStatus } from '../../constants/constants';
import { updateUserRole } from './programOverviewSlice';
import { ProgramAlert } from './programAlert';
import { useGetRPNDetailsQuery } from 'src/components/RPT/services/api';
import { selectVerityData } from './programOverviewSlice';

export const OverviewHome = () => {
    const dispatch = useAppDispatch();
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const locationData = useAppSelector(selectLocationData);
    const { program, fpn } = locationData;
    const roleResMap = useAppSelector(selectRoleResourceMap);
    const { getUserRole } = UserPolicyHelper();
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const programData = useAppSelector(selectVerityData);
    const { isLoading: rpnDataLoading, isFetching: rpnDataFetching }= useGetRPNDetailsQuery(parseInt(program.id), { skip: fpn.id !== '' || !program.id });

    
    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY]);
        dispatch(updateUserRole(currRole));
        if (USER_ACTION_ROLES.approve.includes(currRole)) setHasWriteAccess(true);
    }, [roleResMap, locationData]);

    const showPPLSMissingAlert = !fpn.id && !rpnDataFetching && !rpnDataLoading && !programData.ppls_id?.length && programData.programStatus !== ProgramStatus.TOPSIDE;

    return (
        <>
            <SpaceBetween direction="vertical" size="xl">
                <ProgramAlert
                    programStatus={programData.programStatus as ProgramStatus}
                    showPPLSMissingAlert={showPPLSMissingAlert}
                    hasWriteAccess={hasWriteAccess}
                />
                {!fpn.id && hasWriteAccess && <Box float='right'><SubmitOverview setIsUpdateLoading={setIsUpdateLoading}  /></Box>}
                {!isUpdateLoading ?
                    <>
                        <ProgramOverview />
                        {!fpn.id && <StatusInformation />}
                        <ContactInformation />
                        <Documents />
                    </> :
                    <div className='loadspinner mg-top-md'><Spinner size="large" /></div>
                }
            </SpaceBetween>
        </>

    );
};
