
import {Button, Container, Flashbar, Header, Link, SpaceBetween, Spinner} from '@amzn/awsui-components-react';
import { ColDef, ColGroupDef, GridOptions, ValueGetterParams } from 'ag-grid-community';
import React, { useEffect, useRef, useState } from 'react';
import { GridTable } from 'src/components/common/GridTable/GridTable';
import AddAssortmentModal from './addAssortmentModal';
import useNotification from 'src/components/Hooks/notifications';
import { useAppDispatch, useAppSelector } from 'src/components/redux/hooks';
import { useLazyGetAssortmentsQuery, useRemoveAssortmentMutation } from '../../services/apis/api';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { getAssortments, getAssortmentsLoading, updateExistingAssortmentsStrings } from './assortmentSlice';
import { selectVersions } from '../versions/versionSlice';
import RemoveBtnRenderer from './removeBtnRenderer';
import { getSku } from '../sku/skuSlice';
import { selectAgmRoleResourceMap } from '../userPolicy/userPolicySlice';
import { USER_ACTION_ROLES, ALL_ASSIGNEES_KEY } from '../../constants/constants';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { selectVerityData } from 'src/components/RPT/features/programOverview/programOverviewSlice';
import {AssortmentHelpPanel} from "src/components/AGM/features/assortment/assortmentsHelpPanel";
import {useHelpPanel} from "src/components/common/HelpPanel/useHelpPanel";

export default function Assortment() {
    const dispatch = useAppDispatch();
    const { setHelpPanelContent, setIsOpen, isOpen } = useHelpPanel();
    const locationData = useAppSelector(selectLocationData);
    const versions = useAppSelector(selectVersions);
    const { assortments, assortmentsError } = useAppSelector(getAssortments);
    const assortmentsLoading = useAppSelector(getAssortmentsLoading);
    const { SKUs } = useAppSelector(getSku);
    const roleResMap = useAppSelector(selectAgmRoleResourceMap);
    const pplsId = useAppSelector(selectVerityData).ppls_id;
    useEffect(()=> {
        setHelpPanelContent(<AssortmentHelpPanel />)
    }, [])
    //locals
    const { program, productLine } = locationData;
    const { getUserRole } = UserPolicyHelper();
    //queries
    const [fetchAssortments] = useLazyGetAssortmentsQuery();
    const [removeAssortment] = useRemoveAssortmentMutation();

    //hooks
    const { notificationItems, addNotificationItem, clearNotifications } = useNotification();


    //state
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [visible, setVisible] = React.useState(false);
    const [isLoading, setIsLoading] = useState(!Object.keys(versions).length);
    const [prepareInitialized, setPrepareInitialized] = useState<boolean>(false);

    //Refs
    const assignedAssortments = useRef<number[]>([]);

    //Handlers
    const onRefresh = async () => {
        try {
            setIsLoading(true);
            const response = await fetchAssortments({ verity_rpn_id: program.id });
            if (response.isSuccess) {
                setGridOptions((prevState) => {
                    return {
                        ...prevState,
                        rowData: [...Object.values(assortments ?? {})]
                    };
                });
            } else {
                throw new Error("assortment refresh failed.");
            }
        } catch (error) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: "Error occurred in fetching Assortments.",
                id: "ASSORTMENT_FETCH_FAILED"
            });
        } finally {
            setIsLoading(false);
        }
    };

    const removeAssortmentHandler = async (assortment_id: number) => {
        return  removeAssortment({"assortment_id": assortment_id});
    };

    const getColumnDefs = (): ColDef[] | ColGroupDef[] => {
        return [
            {
                headerName: "Assortment id",
                field: "assortment_id",
                editable: false,
                hide: true
            },
            {
                headerName: "Channel",
                field: "channel",
                editable: false
            },
            {
                headerName: "Country",
                field: "country",
                editable: false,
                maxWidth: 105
            },
            {
                headerName: "ROW",
                field: "row",
                editable: false,
                maxWidth: 105
            },
            {
                headerName: "SKU Type",
                field: "sku_type",
                editable: false
            },
            {
                headerName: "Product Version",
                field: "product_version",
                editable: false,
                valueGetter: (params: ValueGetterParams) => {
                    return versions[params.node?.data.version_id]?.description;
                }
            },
            {
                headerName: "Remove",
                field: "version_id",
                editable: false,
                cellRenderer: RemoveBtnRenderer,
                cellRendererParams: {
                    removeAssortmentHandler,
                    addNotificationItem,
                    assignedAssortments: assignedAssortments.current,
                    hasWriteAccess
                }
            }
        ];
    };

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        defaultColDef: {
            editable: false
        },
        rowData: []
    });

    const customSettings = {
        className: 'ag-theme-alpine',
        readOnly: false,
        allowLocalFilters: true,
        filterId: 'assortments',
        rowHeight: 30,
        prepareInitialized
    };

    const getAddAssortmentTable = () => {
        if (visible) {
            return <AddAssortmentModal visible={visible} setVisible={setVisible} addNotificationItem={addNotificationItem} />;
        }
    };

    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY]);
        setHasWriteAccess((USER_ACTION_ROLES.write.includes(currRole) && pplsId?.length > 0) ? true : false);
    }, [roleResMap, program, productLine, pplsId]);

    useEffect(() => {
        clearNotifications();
    }, [program]);

    useEffect(() => {
        setIsLoading(true);
        if (assortments && Object.keys(versions).length) {
            const assortmentList = Object.values(assortments);
            SKUs?.forEach((sku)=> {
                if(sku.assortment_id && sku.pn){
                    assignedAssortments.current.push(sku.assortment_id);
                }
            });
            setPrepareInitialized(true);
            setGridOptions((prevState) => {
                return {
                    ...prevState,
                    columnDefs: [...getColumnDefs()],
                    rowData: [...assortmentList]
                };
            });

            dispatch(updateExistingAssortmentsStrings(assortmentList.map((assortment: any) => {
                return `${assortment.channel}_${assortment.country ?? assortment.row}_${assortment.sku_type}_${assortment.version_id}`;
            })));
        }
        if(!assortmentsLoading){
            setIsLoading(false);
        }

    }, [assortments, versions, SKUs, hasWriteAccess, assortmentsLoading]);

    useEffect(() => {
        if (assortmentsError) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: "Error occurred in fetching Assortments.",
                id: "ASSORTMENT_FETCH_FAILED"
            });
        }
    }, [assortmentsError]);

    return (
        <>
            <Container key={program.id}
                header={
                    <Header
                        variant="h2"
                        description=""
                        actions={
                            <SpaceBetween
                                direction="horizontal"
                                size="xs"
                            >
                                <Button onClick={() => setVisible(true)} disabled={!hasWriteAccess} >Add Assortment</Button>
                            </SpaceBetween>
                        }
                        info={<Link variant="info" onFollow={() => setIsOpen(!isOpen)}>Info</Link>}
                    >
                        Assortments
                    </Header>
                }
            >
                <SpaceBetween size='l'>
                    <Flashbar items={notificationItems} stackItems />
                    {isLoading
                        ? <div className='loadspinner'><Spinner size="large" /></div>
                        : <GridTable
                            gridSettings={gridOptions}
                            customSettings={{
                                ...customSettings
                            }}
                            onRefresh={onRefresh}
                        />
                    }
                </SpaceBetween>
            </Container>
            {getAddAssortmentTable()}
        </>

    );
}


