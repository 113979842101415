import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StandardAttributes } from './standardAttributes';
import { ProgramAttributes } from './programAttributes';
import { ProductLineAttributes } from './plAttributes';
import { Box, SpaceBetween, Spinner } from '@cloudscape-design/components';
import { useAppDispatch, useAppSelector } from 'src/components/redux/hooks';
import { selectLoadingAPIs, selectOpenCreateFPNModal, updateUserRole } from './attributesSlice';
import { CreateFPNsModal } from './createFPNsModal';
import { AttributeOrderingModal } from './attributeOrderingModal';
import { SubmitAttributes } from './submitAttributes';
import { selectLocationData, selectIsLocked } from '../appLayout/appLayoutSlice';
import { selectRoleResourceMap } from '../userPolicy/userPolicySlice';
import { selectAgmRoleResourceMap } from '../../../AGM/features/userPolicy/userPolicySlice';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import UserPolicyHelperAGM from '../../../AGM/features/userPolicy/userPolicyHelper';
import { ALL_ASSIGNEES_KEY, STATUS_CODES, USER_ACTION_ROLES } from '../../constants/constants';
import { AttributeAlert } from './attributeAlert';
import { GeneralDetails } from './generalInfo';
import { IAttributesOrdering } from '../../interfaces/interfaces';
import { useGetFPNsByRPNQuery } from '../../services/api';
import {useHelpPanel} from "src/components/common/HelpPanel/useHelpPanel";
import {GeneralInfoHelpPanel} from "src/components/RPT/features/attributes/generalInfoHelpPanel";

// This screen is used in AGM & PRM app
export const Attributes = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const locationData = useAppSelector(selectLocationData);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [showCreatePanel, setShowCreatePanel] = useState<boolean>();
    const showModal = useAppSelector(selectOpenCreateFPNModal);
    const roleResMap = useAppSelector(selectRoleResourceMap); // PRM user role 
    const agmRoleResMap = useAppSelector(selectAgmRoleResourceMap); // AGM user role
    const loadingAPIs = useAppSelector(selectLoadingAPIs);
    const { getUserRole } = UserPolicyHelper();
    const { getUserRole : getUserRoleAGM } = UserPolicyHelperAGM();
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [fpnCreationOrder, setFpnCreationOrder] = useState<IAttributesOrdering[]>([]);
    const [fpnsDataError, setFpnsDataError] = useState(false);
    const isLoading = isUpdateLoading || loadingAPIs?.length > 0 ;
    const isLocked = useAppSelector(selectIsLocked);
    const { data: fpnData, isLoading: fpnLoading, isFetching: fpnFectching, isError: fpnError } = useGetFPNsByRPNQuery(parseInt(locationData.program.id), { skip: !locationData.program.id ? true : false });
    const showCreateModalTimeout = (value: boolean) => setTimeout(() => { setShowCreatePanel(value); }, 0);
    const { setHelpPanelContent} = useHelpPanel();
    useEffect(()=> {
        setHelpPanelContent(<GeneralInfoHelpPanel />)
    }, []);

    useEffect(() => {
        const currApp = location.pathname?.split('/')?.[1] ?? '';
        const currRole = currApp === 'agm' ? getUserRoleAGM(agmRoleResMap, [ALL_ASSIGNEES_KEY]) : getUserRole(roleResMap, [ALL_ASSIGNEES_KEY]);
        dispatch(updateUserRole(currRole));
        if (!isLocked && USER_ACTION_ROLES.approve.includes(currRole)) setHasWriteAccess(true);
    }, [agmRoleResMap, roleResMap, locationData, location.pathname, isLocked]);

    useEffect(() => {
        if (fpnError || fpnData?.errors || (fpnData?.data?.get_fpn?.statusCode && fpnData?.data?.get_fpn?.statusCode !== STATUS_CODES.success)) setFpnsDataError(true);
    }, [fpnData, fpnError]);

    return (
        <>
            <AttributeAlert loading={isLoading} />
            <SpaceBetween direction="vertical" size="xl">
                {hasWriteAccess && <Box float="right"><SubmitAttributes setIsUpdateLoading={setIsUpdateLoading} /></Box>}
                {isLoading && <div className='loadspinner mg-top-md'><Spinner size="normal" />Loading...</div>}
                <GeneralDetails />
                <StandardAttributes isLoading={isLoading} />
                <ProductLineAttributes isLoading={isLoading} />
                <ProgramAttributes isLoading={isLoading} />
            </SpaceBetween>
            {!showCreatePanel && showModal && <AttributeOrderingModal setShowCreateModal={showCreateModalTimeout} setFpnCreationOrder={setFpnCreationOrder} />}
            {showCreatePanel && <CreateFPNsModal
                showModal={showCreatePanel}
                setShowModal={showCreateModalTimeout}
                fpnCreationOrder={fpnCreationOrder}
                fpnDataLoading={fpnLoading || fpnFectching}
                fpnDataError={fpnsDataError}
            />
            }
        </>

    );
};
