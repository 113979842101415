import React from 'react';
import {
    Header,
    HelpPanel,
    Icon,
    Link,
    StatusIndicator
} from '@cloudscape-design/components';

import {
    LandingPageResourceSection
} from 'src/components/AppComponents/landingPageConstants';
import {attributeDefinitions} from "src/components/AGM/constants/constants";

const AttributeDefinitionDetailList: React.FC = () => {
    const elements: React.ReactElement[] = [];
    for (const attribute in attributeDefinitions){
    elements.push(<>
        <dt>{attribute}</dt>
        <dd>{attributeDefinitions[attribute]}</dd>
    </>);
    }
return <>{elements}</>
};

export const SkuHelpPanel: React.FC = () => {
    return (
        <HelpPanel

            header={<Header>SKUs</Header>}
            footer={
                <div>
                    <h3>Further questions{" "}<Icon name="external" size="inherit"/></h3>
                    <ul>
                        <div><Link href={LandingPageResourceSection.contactLink} target="_blank">Create SIM
                            ticket</Link></div>
                    </ul>
                </div>
            }
        >
            This tab displays the SKU mappings and allows you to add or modify a SKU's version and/or assortment.
            <h3>Definitions</h3>
            <dl>
                <AttributeDefinitionDetailList/>
            </dl>
            <h3>Key Points</h3>
            <p>Each SKU should belong to one and only one FPN.</p>
            <p>The FPN is determined by comparing the ASIN's attributes to the RPN's attribute rules.</p>
            <h3>Status Indicators Definition</h3>
            <p><StatusIndicator type="success"></StatusIndicator>: The FPN exists within Verity.</p>
            <p><StatusIndicator type="warning"></StatusIndicator>: This FPN hasn't been created for this RPN. This
                occurs when the SKU's version map to an FPN that has NOT been created.</p>

            <h3>Questions?</h3>
            <dl>
                <dt>
                    What to do if I see a <StatusIndicator type="warning"></StatusIndicator>?
                </dt>
                <dd>
                    Contact the RPN's PM owner or the appropriate team to create this missing FPN. Contact information
                    can be found in PRM on the RPN's Overview tab.
                </dd>
            </dl>
        </HelpPanel>
    );
};
