import React from 'react';
import {
    Header,
    HelpPanel,
    Icon,
    Link,
} from '@cloudscape-design/components';

import {
    LandingPageResourceSection
} from 'src/components/AppComponents/landingPageConstants';

export const VersionHelpPanel: React.FC = () => {
    return (
        <HelpPanel

            header={<Header>Versions</Header>}
            footer={
                <div>
                    <h3>Further questions{" "}<Icon name="external" size="inherit"/></h3>
                    <ul>
                        <div><Link href={LandingPageResourceSection.contactLink} target="_blank">Create SIM
                            ticket</Link></div>
                    </ul>
                </div>
            }
        >
            <h3>Versions</h3>
            <p>A version is a unique combination of a program's attributes.</p>

        </HelpPanel>
    );
};
