import React, { useState } from "react";
import { Alert, Button, Link } from "@cloudscape-design/components";
import { useAppSelector } from "../../../redux/hooks";
import { selectIsLocked } from '../appLayout/appLayoutSlice';
import { ProgramStatus, ALERT_MESSAGES } from "../../constants/constants";
import { CreateProgramPPLS } from "./createProgramPPLS";

interface ProgramAlertProps {
    programStatus: ProgramStatus;
    showPPLSMissingAlert: boolean;
    hasWriteAccess: boolean;
}

export const ProgramAlert: React.FC<ProgramAlertProps> = ({
    programStatus,
    showPPLSMissingAlert,
    hasWriteAccess
}) => {
    const [pplsModal, setPplsModal] = useState(false);

    if (programStatus === ProgramStatus.TOPSIDE) {
        return (
            <Alert
                statusIconAriaLabel="Info"
                type="info"
                header={ALERT_MESSAGES.topside.header}
            >
                {ALERT_MESSAGES.topside.content}
            </Alert>
        );
    }

    if (showPPLSMissingAlert) {
        return (
            <>
                <Alert
                    statusIconAriaLabel="Warning"
                    type="warning"
                    action={
                        hasWriteAccess && (
                            <Button onClick={() => setPplsModal(true)}>Create Program in PPLS</Button>
                        )
                    }
                    header={ALERT_MESSAGES.pplsMissing.header}
                >
                    {hasWriteAccess ? (
                        ALERT_MESSAGES.pplsMissing.content
                    ) : (
                        <>
                            {ALERT_MESSAGES.pplsMissing.noAccessContent}
                            <Link
                                href={ALERT_MESSAGES.pplsMissing.adminAccessRequestUrl}
                                external
                            >
                                HERE
                            </Link>
                        </>
                    )
                    }
                </Alert>
                <CreateProgramPPLS setVisible={setPplsModal} visible={pplsModal} />
            </>
        );
    }

    return <OverviewAlert />;
};



export const OverviewAlert = ({loading}: any) => {
    const isLocked = useAppSelector(selectIsLocked);

    return (
        <> 
            {isLocked && <Alert
                className="mg-bottom-md"
                statusIconAriaLabel="Warning"
                type="warning"
                header="'Change program name/status' is currently locked for OP1 first pass, please contact @huberwan for more information."
            />}
        </>
    );
};
