import React, { useEffect, useState } from 'react';
import {
    AppLayout,
    Box,
    Container,
    ColumnLayout,
    ContentLayout,
    Header,
    Icon,
    Link,
    SpaceBetween,
    Grid
} from "@amzn/awsui-components-react";
import { RootState } from '../redux/store';
import { Outlet } from 'react-router-dom';
import { SideNav } from '../common/Header/sideNav';
import { AlertMessageProvider } from '../../components/common/Contexts/alertMessageContext';
import { TopNav } from '../common/Header/topNav';
import { appName } from 'src/constants/constants';
import { useAppDispatch } from '../redux/hooks';
import { resetUserPolicy } from '../RPT/features/userPolicy/userPolicySlice';

import { LandingPageResourceSection, LandingPageSection, LandingPageReportSection } from './landingPageConstants';
import ReportCards from "../dataReports/reportCards";

export function HomeAppLayout() {
    const [hideSideNav] = useState<boolean>(false);
    const [navigationOpen, setNavigationOpen] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const onNavigationChange = () => {
        setNavigationOpen(!navigationOpen);
    };
    useEffect(() => {
        dispatch(resetUserPolicy());
    }, []);

    return (
        <>
            <TopNav appName={appName} />
            <AppLayout
                id="appLayout"
                content={
                    <ContentLayout
                        disableOverlap
                        header={
                            <Header variant="h1" description={LandingPageSection.MainHeaderTagline}>
                                {LandingPageSection.MainHeader}
                            </Header>
                        }
                    >
                        <Box margin={{ top: 'l' }}>
                            <SpaceBetween size="l">
                                <AlertMessageProvider>
                                    <Outlet />
                                </AlertMessageProvider>
                            </SpaceBetween>
                        </Box>
                        <Grid
                            gridDefinition={[
                                { colspan: 8 },
                                { colspan: 4 }
                            ]}
                        >
                            <div>
                                <Container header={<Header variant="h2">{LandingPageSection.HowItWorks}</Header>}>
                                    <Box variant="p" color="text-body-secondary">
                                        {LandingPageSection.HowItWorksDescription}
                                    </Box>
                                </Container>
                            </div>
                            <div>
                                <Container header={<Header variant="h2">{LandingPageResourceSection.Header}<Icon name="external" /></Header>}>
                                    <ColumnLayout
                                        borders="horizontal" columns={1}
                                    >
                                        <div><Link href={LandingPageResourceSection.documentationLink} target="_blank">{LandingPageResourceSection.documentationHeader}</Link></div>
                                        <div><Link href={LandingPageResourceSection.contactLink} target="_blank">{LandingPageResourceSection.contactHeader}</Link></div>
                                        <div><Link href={LandingPageResourceSection.enhancementRequestLink} target="_blank">{LandingPageResourceSection.enhancementRequestHeader}</Link></div>
                                        <div><Link href={LandingPageResourceSection.newProjectRequestLink} target="_blank">{LandingPageResourceSection.newProjectRequestHeader}</Link></div>
                                        <div><Link href={LandingPageResourceSection.requestReportAccessLink} target="_blank">{LandingPageResourceSection.requestReportAccessHeader}</Link></div>
                                        <div><Link href={LandingPageResourceSection.fintechPortalLink} target="_blank">{LandingPageResourceSection.fintechPortalHeader}</Link></div>
                                        <div><Link href={LandingPageResourceSection.updateProgramInPPLSLink} target="_blank">{LandingPageResourceSection.updateProgramInPPLSHeader}</Link></div>
                                    </ColumnLayout>
                                </Container>
                            </div>
                        </Grid>

                        <Box>
                            <SpaceBetween size="l">
                                <AlertMessageProvider>
                                    <Outlet />
                                </AlertMessageProvider>
                            </SpaceBetween>
                        </Box>

                        <Box margin={{ top: 'l' }}>
                            <Container header={<Header variant="h2">{LandingPageReportSection.Header}</Header>}>
                                <Box variant="p" color="text-body-secondary">
                                    {LandingPageReportSection.Description}
                                </Box>
                                <SpaceBetween size="l">
                                    <ReportCards />
                                </SpaceBetween>
                            </Container>
                        </Box>

                    </ContentLayout>

                }
                navigation={<SideNav />}
                navigationHide={hideSideNav}
                navigationOpen={navigationOpen}
                onNavigationChange={() => onNavigationChange()}
                toolsHide={true}
                contentType="default"
            />

        </>
    );
}