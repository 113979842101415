import React from 'react';
import {
    Header,
    HelpPanel,
    Icon,
    Link,
    StatusIndicator
} from '@cloudscape-design/components';

import {
    LandingPageResourceSection
} from 'src/components/AppComponents/landingPageConstants';

export const GeneralInfoHelpPanel: React.FC = () => {
    return (
        <HelpPanel

            header={<Header>FPN Rule</Header>}
            footer={
                <div>
                    <h3>Further questions{" "}<Icon name="external" size="inherit"/></h3>
                    <ul>
                        <div><Link href={LandingPageResourceSection.contactLink} target="_blank">Create SIM
                            ticket</Link></div>
                    </ul>
                </div>
            }
        >
            <h3>FPN Rule</h3>
            <p>The FPN (Finance Program Name) Rule defines how an FPN name is generated based on the attributes of the program version.</p>
            <p>Any attribute in a square brackets (i.e. "[ ]") indicate an optional attribute for this FPN.</p>
            <div/>
            <p>For example, the FPN Rule "Program_[DESIGN TYPE]_[KIDS EDITION]_MEMORY_[WAN]_DTCP" only requires Program, Memory, and DTCP. Design Type, Kids Edition, and WAN are optional.</p>
        </HelpPanel>
    );
};
