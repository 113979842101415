export const SANDBOX_UNAPPROVED_MESSAGE = 'There are changes in the sandbox that are not yet pushed to WIP.';

export const VIEW_WIP_BUTTON = 'View WIP data';

export const APP_LAYOUT_MESSAGES = {
    authError : 'Error: Please reload screen to view assigned programs',
    policyLoading: 'Fetching assigned programs...',
    progLoading: 'Loading programs...',
    updatingProg: 'Updating programs...',
    progLoadingError: 'Error loading programs. Please reload and try again',
    userPolicyError: 'Error fetching assigned programs. Please reload and try again'
};

export const ALERT_MESSAGES = {
    attrValueSuccess : 'Successfully added attribute value ',
    attrTypeSuccess : 'Successfully added attribute ',
    attrValueFail : 'Error adding attribute value',
    attrTypeFail : 'Error adding attribute',
    userPolicyGetError : 'Error fetching user permissions. Please refresh and try again.',
    addPolicySuccess: 'Successfully assigned user permission',
    addPolicyError: 'Error assigning user permission. Please refresh and try again.',
    removePolicySuccess: 'Successfully removed user permission',
    removePolicyError: 'Error removing user permission. Please refresh and try again.'
};

export const PROGRAM_ALERT_MESSAGES = {
    pplsCreateSuccess: 'Successfully created program in ppls',
    pplsCreateError: 'Program creation failed in ppls. Please try again later',
};

export const SNAPSHOT_MESSAGES = {
    snroGetError: 'Error fetching scenarios',
    snapshotRfrSuccess: 'Successfully refreshed snapshot',
    snapshotRfrError: 'Error refreshing snapshot',
    snapshotRfrWIPSuccess: 'Successfully refreshed WIP',
    snapshotRfrWIPError: 'Error refreshing WIP',
    snapshotCreateSuccess: 'Successfully created snapshot',
    snapshotCreateError: 'Error creating snapshot',
    snapshotRfrWIPTimeout: 'Snapshot refresh in progress please check back in 5 minutes'
};

export const GANTT_CHART_MESSAGES = {
    getDataError: 'Error fetching chart date. Please try again later.'
};

export const ACCESS_DENIED_MESSAGES = {
    adminPanel: "You don't have admin permissions to view this page. Please create a SIM ticket to request access.",
    snapshot: "You don't have permissions to view this page. Please create a SIM ticket to request access.",
    attrRequest: "You don't have permissions to view this page."
};