import React from 'react';
import { Routes, Route, HashRouter, Navigate } from "react-router-dom";
import { Projects } from '../../screens/projects';
import { ProductGroup } from '../../screens/productGroup';
import { ExpenseUpdate } from '../../screens/expenseUpdate';
import { AccessoryProject } from '../../screens/accessoryProject';
import { Account } from '../../screens/account';
import { Commodity } from '../../screens/commodity';
import { CostCenterExpLine } from '../../screens/costCenterExpLine';
import { CostCenter } from '../../screens/costCenter';
import { BudgetType } from '../../screens/budgetType';
import { CostCenterGroup } from '../../screens/costCenterGroup';
import { Product } from '../../screens/product';
import { BudgetBaseline } from '../../screens/budgetBaseline';
import { ExpenseType } from '../../screens/expenseType';

import { ProjectsContextProvider } from '../context/projectsContext';
import { ProductGroupContextProvider } from '../context/productGroupContext';
import { ExpenseUpdateContextProvider } from '../context/expenseUpdateContext';
import { AccessoryProjectContextProvider } from '../context/accessoryProjectContext';
import { AccountContextProvider } from '../context/accountContext';
import { CommodityContextProvider } from '../context/commodityContext';
import { CostCenterExpLineContextProvider } from '../context/costCenterExpLineContext';
import { CostCenterContextProvider } from '../context/costCenterContext';
import { BudgetTypeContextProvider } from '../context/budgetTypeContext';
import { CostCenterGroupContextProvider } from '../context/costCenterGroupContext';
import { ProductContextProvider } from '../context/productContext';
import { BudgetBaselineContextProvider } from '../context/budgetBaselineContext';
import { ExpenseTypeContextProvider } from '../context/expenseTypeContext';
import { GlobalContextProvider } from '../context/globalContext';
import { CoaAppLayout } from '../../screens/common/coaAppLayout';
import { HomeAppLayout } from './AppLayout';
import { RPTAppLayout } from '../RPT/features/appLayout/rptAppLayout';
import { ProgramSetup } from 'src/components/RPT/features/programSetup/programSetupHome';
import { ProductLineHome } from 'src/components/RPT/features/productLineHome/productLineHome';
import { CreateNewProgram } from 'src/components/RPT/features/home/createNewProgram';
import { RequestNewAttribute } from 'src/components/RPT/features/home/requestAttribute';
import { Snapshot } from 'src/components/RPT/features/home/createSnapshot';
import { RequestNewAttributeValue } from 'src/components/RPT/features/home/requestAttributeValue';
import { PRMAdminPanel } from 'src/components/RPT/features/userPolicy/adminPanel/adminPanel';
import { ScenarioConfigManager } from 'src/components/Verity/features/scenarioConfigManager/scenarioConfig';
import { RPTHome } from 'src/components/RPT/features/home/home';
import { VerityAppLayout } from 'src/components/Verity/features/appLayout/verityAppLayout';
import { ScenarioAdminPanel } from 'src/components/Verity/features/userPolicy/adminPanel/scenarioAdmin/scenarioAdminPanel';
import { AGMAppLayout } from '../AGM/features/appLayout/agmAppLayout';
import { ProgramHome } from '../AGM/features/programHome/programHome';
import { AGMAdminPanel } from 'src/components/AGM/features/userPolicy/adminPanel/adminPanel';
import { ProductRoadmapChart } from '../RPT/features/ganttChart/productRoadmap/chart';
import {HelpPanelProvider} from "src/components/common/HelpPanel/HelpPanelProvider";

export function RouterContent() {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<HomeAppLayout />}>
                </Route>
                <Route path="#/" element={<HomeAppLayout />}>
                </Route>
                <Route path="agm" element={
                    <HelpPanelProvider>
                        <AGMAppLayout />
                    </HelpPanelProvider>
                }>
                    <Route path="program" element={<ProgramHome />}></Route>
                    <Route path="admin" element={<AGMAdminPanel />}></Route>
                </Route>
                <Route path="prm" element={<RPTAppLayout />}>
                    <Route path="" element={<RPTHome />}></Route>
                    <Route path="program-setup" element={<ProgramSetup />}></Route>
                    <Route path="product-line-home" element={<ProductLineHome />}></Route>
                    <Route path="create-new-program" element={<CreateNewProgram />}></Route>
                    <Route path="create-snapshot" element={<Snapshot />}></Route>
                    <Route path="request-new-attribute" element={<RequestNewAttribute />}></Route>
                    <Route path="request-new-attribute-value" element={<RequestNewAttributeValue />}></Route>
                    <Route path="admin" element={<PRMAdminPanel />}></Route>
                    <Route path="product-roadmap-chart" element={<ProductRoadmapChart />}></Route>
                </Route>
                <Route path="rpt" element={<Navigate to="/prm" />}>
                    <Route path="*" element={<Navigate to="/prm" />}></Route>
                </Route>
                <Route path="coa" element={<CoaAppLayout />}>
                    <Route
                        path="accessoryProject"
                        element={
                            <GlobalContextProvider>
                                <AccessoryProjectContextProvider >
                                    <AccessoryProject />
                                </AccessoryProjectContextProvider >
                            </GlobalContextProvider>
                        }
                    ></Route>
                    <Route
                        path="account"
                        element={
                            <GlobalContextProvider>
                                <AccountContextProvider >
                                    <Account />
                                </AccountContextProvider >
                            </GlobalContextProvider>
                        }
                    ></Route>
                    <Route
                        path="commodity"
                        element={
                            <CommodityContextProvider >
                                <Commodity />
                            </CommodityContextProvider >
                        }
                    ></Route>
                    <Route
                        path="costCenterExpLine"
                        element={
                            <GlobalContextProvider>
                                <CostCenterExpLineContextProvider >
                                    <CostCenterExpLine />
                                </CostCenterExpLineContextProvider >
                            </GlobalContextProvider>
                        }
                    ></Route>
                    <Route
                        path="product"
                        element={
                            <GlobalContextProvider>
                                <ProductContextProvider >
                                    <Product />
                                </ProductContextProvider >
                            </GlobalContextProvider>
                        }
                    ></Route>
                    <Route
                        path="costCenterGroup"
                        element={
                            <CostCenterGroupContextProvider >
                                <CostCenterGroup />
                            </CostCenterGroupContextProvider >
                        }
                    ></Route>
                    <Route
                        path="costCenter"
                        element={
                            <GlobalContextProvider>
                                <CostCenterContextProvider >
                                    <CostCenter />
                                </CostCenterContextProvider >
                            </GlobalContextProvider>
                        }
                    ></Route>
                    <Route
                        path="budgetType"
                        element={
                            <BudgetTypeContextProvider >
                                <BudgetType />
                            </BudgetTypeContextProvider >
                        }
                    ></Route>
                    <Route
                        path="projects"
                        element={
                            <GlobalContextProvider>
                                <ProjectsContextProvider >
                                    <Projects />
                                </ProjectsContextProvider >
                            </GlobalContextProvider>
                        }
                    ></Route>
                    <Route
                        path="productgroup"
                        element={
                            <ProductGroupContextProvider >
                                <ProductGroup />
                            </ProductGroupContextProvider >
                        }
                    ></Route>
                    <Route
                        path="expenseType"
                        element={
                            <GlobalContextProvider>
                                <ExpenseTypeContextProvider >
                                    <ExpenseType />
                                </ExpenseTypeContextProvider >
                            </GlobalContextProvider>
                        }
                    ></Route>
                    <Route
                        path="expenseUpdateReason"
                        element={
                            <ExpenseUpdateContextProvider >
                                <ExpenseUpdate />
                            </ExpenseUpdateContextProvider >
                        }
                    ></Route>
                    <Route
                        path="budgetBaseline"
                        element={
                            <GlobalContextProvider>
                                <BudgetBaselineContextProvider >
                                    <BudgetBaseline />
                                </BudgetBaselineContextProvider >
                            </GlobalContextProvider>
                        }
                    ></Route>
                </Route>

                <Route path="agm" element={<AGMAppLayout />}>
                    <Route path="program" element={<ProgramHome />}></Route>
                </Route>
            </Routes >
        </HashRouter >
    );
}